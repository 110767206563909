<template>
  <div
    class="w-100 d-flex flex-column justify-content-between conversation-container"
  >
    <div>
      <div class="user-container">
        <span class="font-weight-bold user-name"> {{ receiver.name }} </span>

        <img
          class="mt-2 horizontal-dots"
          :src="horizontalDotsIcon"
          @click="showDeleteConversation"
        />

        <button
          v-if="isDeleteConversationShown"
          class="delete-conversation pa-1"
          @click="deleteMessage"
        >
          <div class="delete-conversation-container py-1 pr-1 pl-4">
            <img :src="deleteConversationIcon" alt="Delete Icon"/>
            <span class="mr-2 delete-conversation-text">حذف المحادثة</span>
          </div>
        </button>
      </div>

      <div id="chatScrolling" class="mt-10 chat-container">
        <div
          v-for="message in messages"
          :key="message._id"
          class="messages-container mt-4"
        >
          <p :class="['message', setMessageStatus(message.sender)]">
            {{ message.content }}
          </p>
        </div>
      </div>
    </div>

    <div class="new-message-container">
      <input
        class="w-100 pl-4 new-message-input"
        placeholder="رسالتك هنا"
        :disabled="isSendingMessage"
        v-model="newMessage"
        @keyup.enter="addMessage"
      />

      <button
        :class="[
          'add-message-btn',
          { 'disable-add-message': isSendingMessage }
        ]"
      >
        <img :src="sendNewMessageIcon" @click="addMessage" alt="Send Message Icon" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { messagingService } from '../../services/messaging.service'
export default {
  name: 'ConversationDetails',
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newMessage: '',
      isDeleteConversationShown: false,
      isSendingMessage: false,
      deleteConversationIcon: require('@/assets/images/svgs/messages/delete-conversation.svg'),
      horizontalDotsIcon: require('@/assets/images/svgs/messages/horizontal-dots.svg'),
      sendNewMessageIcon: require('@/assets/images/svgs/messages/send-new-message.svg'),
      newMessageIsEmpty: true
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    receiver() {
      if (this.messages[0].sender._id != this.user._id)
        return this.messages[0].sender
      else return this.messages[0].receiver
    }
  },
  methods: {
    showDeleteConversation() {
      this.isDeleteConversationShown = !this.isDeleteConversationShown
    },
    setMessageStatus(messageSender) {
      if (this.user._id == messageSender._id) return 'outgoing-message'
      else return 'incoming-message'
    },
    addMessage() {
      this.isSendingMessage = true
      let newMessage = this.newMessage.trim()
      if (newMessage.length == 0) {
        this.isSendingMessage = false
        return
      }
      let messageBody = {
        content: newMessage,
        sender: {
          _id: this.user._id,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          email: this.user.email
        },
        receiver: this.receiver,
        context: {
          platform: 'hectar'
        },
        thread: this.messages[0].thread
      }
      messagingService
        .addMessage(messageBody)
        .then(async res => {
          if (res.data.added) {
            await this.$emit('message-added', messageBody)
            this.newMessage = ''
            let chatContainer = this.$el.querySelector('#chatScrolling')
            chatContainer.scrollTop = chatContainer.scrollHeight
          }
        })
        .catch(() => {
          this.$toasted.show('خطأ في ارسال الرسالة', {
            duration: 2000,
            type: 'error',
            icon: 'fa-close'
          })
        })
        .finally(() => {
          this.isSendingMessage = false
        })
    },
    deleteMessage() {
      this.isDeleteConversationShown = false
      this.$emit('delete-message', this.messages[0].thread)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/chat/conversation-details';
</style>
