import config from '../config'
import { authHeader } from '../store/helpers'
import axios from 'axios'
export const messagingService = {
  getAllThreads,
  getMessages,
  addMessage,
  setMessageSeen,
  deleteThread
}

const requestOptions = {
  headers: authHeader()
}

// get All Threads for a spacific user
function getAllThreads(userId) {
  const url =
    config.messagesUrl + `/messages/getThreads?user=${userId}&context=hectar`
  return axios.get(url, requestOptions)
}

//get Messages for a single thread
function getMessages(threadId) {
  const url = config.messagesUrl + `/messages?thread=${threadId}`
  return axios.get(url, requestOptions)
}

// post a new message
function addMessage(data) {
  const url = config.messagesUrl + `/messages`
  return axios.post(url, data, requestOptions)
}

//set Messages as seen
function setMessageSeen(threadId) {
  const url = config.messagesUrl + `/messages/set-as-seen/${threadId}`
  return axios.put(url, requestOptions)
}

//delete single thread
function deleteThread(threadId) {
  const url = config.messagesUrl + `/messages/delete-thread/${threadId}`
  return axios.delete(url, requestOptions)
}
