<template>
  <div class="no-messages-container">
    <div class="text-center">
      <img :src="noMessagesIcon" />
      <h3 class="no-messages">لا يوجد رسائل من عملاء هكتار</h3>
      <h6 class="no-messages-hectar">لا يوجد رسائل من عملاء هكتار</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoMessages",
  data() {
    return {
      noMessagesIcon: require("@/assets/images/svgs/messages/no-messages.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.no-messages-container {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  height: 653px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-messages {
  margin-top: 28px;
  color: #101010;
}
.no-messages-hectar {
  margin-top: 12px;
  color: #737373;
}
</style>
