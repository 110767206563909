<template>
  <div class="conversations-container">
    <b-container class="message-container">
      <b-overlay :show="threadsContainerLoading" rounded="lg" opacity="1">
        <b-row no-gutters v-if="userThreads.length > 0">
          <b-col sm="5" md="4" class="messages-border d-none d-sm-block">
            <div class="p-3 mx-auto messages-title">
              <span class="font-weight-bold">الرسائل</span>
            </div>
            <b-overlay :show="threadsAreLoading" rounded="lg" :opacity="1">
              <div class="threads-container">
                <MessageCard
                  v-for="(thread, index) in userThreads"
                  :key="thread._id"
                  :thread-data="thread"
                  :thread-id="threadId"
                  @active-thread="setActiveThread($event, index)"
                />
              </div>
            </b-overlay>
          </b-col>

          <b-col sm="7" md="8" cols="12" class="">
            <div class="conversation">
              <b-overlay
                :show="conversationMessagesIsLoading"
                rounded="lg"
                :opacity="1"
                class="conversation"
              >
                <img
                  v-if="expandThreadsIsShown"
                  class="d-inline d-sm-none ml-1 expand-threads"
                  :src="expandThreadsIcon"
                  @click="expandThreads"
                  alt="Message Toggle Image"
                />

                <ConversationDetails
                  v-if="conversationMessages.length > 0"
                  :messages="conversationMessages"
                  @message-added="addMessageToConversation"
                  @delete-message="deleteMessage"
                />
              </b-overlay>

              <div class="xs-messages d-block d-sm-none" v-if="xsThreadsShown">
                <div class="p-3 xs-messages-header">
                  <span class="font-weight-bold">الرسائل</span>
                  <img
                    :src="closeXsThreads"
                    class="close-expand-messages"
                    @click="closeThreads"
                    alt="Message Toggle Image"
                  />
                </div>
                <b-overlay
                  :show="threadsAreLoading"
                  rounded="lg"
                  :opacity="1"
                  class="xs-messages-body"
                >
                  <MessageCard
                    v-for="(thread, index) in userThreads"
                    :key="thread._id"
                    :thread-data="thread"
                    :thread-id="threadId"
                    @active-thread="setActiveThread($event, index)"
                  />
                </b-overlay>
              </div>
            </div>
          </b-col>
        </b-row>

        <div v-else>
          <NoMessages />
        </div>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import ConversationDetails from '@/components/messages/ConversationDetails.vue'
import MessageCard from '@/components/messages/MessageCard.vue'
import NoMessages from '@/components/messages/NoMessages.vue'
import { messagingService } from '../../services/messaging.service'
import { mapState } from 'vuex'

export default {
  components: {
    ConversationDetails,
    MessageCard,
    NoMessages
  },
  data() {
    return {
      userThreads: [],
      threadsAreLoading: null,
      conversationMessagesIsLoading: null,
      threadId: '',
      expandThreadsIsShown: true,
      xsThreadsShown: false,
      closeXsThreads: require('@/assets/images/svgs/messages/close-xs-messages.svg'),
      conversationMessages: [],
      expandThreadsIcon: require('@/assets/images/svgs/messages/chevron-left.svg'),
      threadsContainerLoading: null
    }
  },
  computed: {
    ...mapState('accountState', ['user'])
  },
  methods: {
    addNewMessage(message) {
      return messagingService.addMessage(message).then(res => {
        messagingService.getAllThreads(this.user._id).then(res => {
          this.userThreads = res.data.threads
        })
        this.threadId = res.data.thread
        this.getConversationByThread(res.data.thread)
      })
    },
    getUserThreads(userId) {
      return messagingService.getAllThreads(userId).then(res => {
        this.userThreads = res.data.threads
        if (this.userThreads[0]) {
          this.threadId = this.userThreads[0]._id
          this.setAsSeen(this.userThreads[0], 0)
          this.getConversationByThread(this.userThreads[0]._id)
        }
      })
    },
    getConversationByThread(threadId) {
      this.conversationMessagesIsLoading = true
      messagingService
        .getMessages(threadId)
        .then(res => {
          this.conversationMessages = res.data.items
        })
        .finally(() => {
          this.conversationMessagesIsLoading = false
        })
    },
    setAsSeen(thread, threadIndex) {
      if (!thread.seen && thread.receiverId === this.user._id) {
        return messagingService.setMessageSeen(thread._id).then(() => {
          this.$set(this.userThreads[threadIndex], 'seen', true)
        })
      }
      return null
    },
    setActiveThread(thread, threadIndex) {
      this.threadId = thread._id
      this.setAsSeen(thread, threadIndex)
      this.getConversationByThread(thread._id)
    },
    expandThreads() {
      this.expandThreadsIsShown = false
      this.xsThreadsShown = true
    },
    closeThreads() {
      this.expandThreadsIsShown = true
      this.xsThreadsShown = false
    },
    addMessageToConversation(message) {
      this.conversationMessages.push(message)
    },
    async deleteMessage(threadId) {
      await messagingService.deleteThread(threadId)
      this.threadsAreLoading = true
      this.getUserThreads(this.user._id).finally(() => {
        this.threadsAreLoading = false
      })
    }
  },
  created() {
    if (this.$route.query.receiver) {
      let receiverQuery = JSON.parse(this.$route.query.receiver)
      let sender = {
        _id: this.user._id,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email
      }
      let messageBody = {
        content: `أهلا ${receiverQuery.receiver.name}`,
        sender,
        receiver: receiverQuery.receiver,
        context: {
          platform: 'hectar'
        },
        subjectId: receiverQuery.subjectId
      }
      this.threadsContainerLoading = true
      this.addNewMessage(messageBody).finally(() => {
        this.threadsContainerLoading = false
      })
    } else {
      this.getUserThreads(this.user._id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/chat/index';
</style>
