export default {
  data() {
    return {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ],
      days: [
        'الأحد',
        'الإثنين',
        'الثلاثاء',
        'الأربعاء',
        'الخميس',
        'الجمعه',
        'السيت'
      ]
    }
  },
  methods: {
    formatDate(date) {
      const d = new Date(date)
      return `${d.getDate()}  ${this.months[d.getMonth()]} ${d.getFullYear()}`
    },
    getTheDay(date) {
      const currentDay = new Date(date)
      return this.days[currentDay.getDay()]
    }
  }
}
