<template>
  <div
    @click="$emit('active-thread', threadData)"
    :class="['message-card-container', setThreadActiveClass]"
  >
    <div class="d-flex">
      <div class="d-none d-sm-block name-abbreviation">
        {{ nameAbbreviation }}
      </div>
      <div class="mr-2">
        <p class="user-name m-0 pb-0 d-inline-block">{{ threadData.name }}</p>
        <div class="unread-badge" v-if="messagesUnseen"></div>
        <p class="last-message m-0 pt-0">{{ threadData.lastMessage }}</p>
      </div>
    </div>
    <span :class="['time-last-message', lastMessageHourColor]">{{
      formatDate(threadData.sentAt)
    }}</span>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapState } from 'vuex'

export default {
  name: 'MessageCard',
  mixins: [dateFormat],
  props: {
    threadData: {
      type: Object,
      required: true
    },
    threadId: {
      type: [String, Number]
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('accountState', ['user']),
    setThreadActive() {
      return this.threadId === this.threadData._id
    },
    setThreadActiveClass() {
      if (this.setThreadActive) return 'active-thread'
      else return ''
    },
    messagesUnseen() {
      return (
        !this.threadData.seen && this.threadData.receiverId === this.user?._id
      )
    },
    lastMessageHourColor() {
      if (this.messagesUnseen) return 'time-last-message-color'
      return ''
    },
    nameAbbreviation() {
      let x = this.threadData.name?.replace(' ', ',')
      let arr = x.split(',')
      let str = arr.map((str) => {
        return str.substring(0, 1)
      })
      return str.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/chat/message-card';
</style>
